import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

export const ServicesPageTemplate = ({ title, description, image, header, mainpitch, main }) => {
    const editingRef = useRef(null);
    const proofreadingRef = useRef(null);
    const academicEditingRef = useRef(null);
    const editorialAssessmentRef = useRef(null);

    const [copyeditingToggle, toggleCopyediting] = useState(false);
    const [proofreadingToggle, toggleProofreading] = useState(false);
    const [academicEditingToggle, toggleAcademicEditing] = useState(false);
    const [editorialAssessmentToggle, toggleEditorialAssessment] = useState(false);

    useEffect(() => {
        let url = typeof window !== "undefined" ? window.location.href : "";
        if (url.includes("#editing")) {
            toggleCopyediting(true);
            editingRef.current.scrollIntoView();
            return;
        }
        if (url.includes("#proof-reading")) {
            toggleProofreading(true);
            proofreadingRef.current.scrollIntoView();
            return;
        }
        if (url.includes("#academic-editing")) {
            toggleAcademicEditing(true);
            academicEditingRef.current.scrollIntoView();
            return;
        }
        if (url.includes("#editorial-assessment")) {
            toggleEditorialAssessment(true);
            editorialAssessmentRef.current.scrollIntoView();
            return;
        }
    }, []);

    return (
        <div className="content">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
            </Helmet>
            {/* banner image */}
            <div
                className="full-width-image margin-top-0"
                style={{
                    backgroundImage: `url(${
                        !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`,
                    backgroundAttachment: `fixed`,
                    backgroundPosition: `top`,
                }}
            ></div>
            <section className="section section--gradient has-background-white has-text-black">
                <div className="container">
                    <div className="section">
                        <div className="columns">
                            <div className="column is-10 is-offset-1 has-text-justified">
                                <div className="content">
                                    {/* title */}
                                    <div className="content">
                                        <div className="tile">
                                            <h1 className="title has-text-black">{header}</h1>
                                        </div>
                                        <div className="tile">
                                            <h3 id="home-pitch" className="subtitle has-text-black">
                                                {mainpitch.description}
                                            </h3>
                                        </div>
                                    </div>
                                    {/* SERVICES */}
                                    {/* editing */}
                                    <div
                                        className="collapsible-header mb-3"
                                        ref={editingRef}
                                        onClick={() => {
                                            copyeditingToggle
                                                ? toggleCopyediting(false)
                                                : toggleCopyediting(true);
                                        }}
                                    >
                                        <img
                                            className="collapsible-header_image"
                                            src={
                                                Boolean(main.editing.image) &&
                                                Boolean(main.editing.image.childImageSharp)
                                                    ? main.editing.image.childImageSharp.fixed.src
                                                    : main.editing.image
                                            }
                                            alt={main.editing.heading}
                                        ></img>
                                        <h2 className="collapsible-header_title title">
                                            <span className="collapsible-header_title_first-half">Copy</span>
                                            <span className="has-text-black">editing</span>
                                        </h2>
                                    </div>
                                    {copyeditingToggle && (
                                        <article id="copy-editing_text" className="ml-3 mr-3">
                                            <div
                                                className="has-text-black"
                                                dangerouslySetInnerHTML={{ __html: main.editing.text }}
                                            />
                                            <table className="table is-bordered is-narrow rates_table">
                                                <tbody>
                                                    {main.editing.rates.map((rate, i) => (
                                                        <tr key={i}>
                                                            <td>{rate.service}</td>
                                                            <td>{rate.pricePound}</td>
                                                            <td>{rate.priceEuro}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </article>
                                    )}

                                    {/* proofreading */}
                                    <div
                                        className="collapsible-header mb-3"
                                        ref={proofreadingRef}
                                        onClick={() => {
                                            proofreadingToggle
                                                ? toggleProofreading(false)
                                                : toggleProofreading(true);
                                        }}
                                    >
                                        <img
                                            className="collapsible-header_image"
                                            src={
                                                Boolean(main.proofReading.image) &&
                                                Boolean(main.proofReading.image.childImageSharp)
                                                    ? main.proofReading.image.childImageSharp.fixed.src
                                                    : main.proofReading.image
                                            }
                                            alt={main.proofReading.heading}
                                        ></img>
                                        <h2 className="collapsible-header_title title">
                                            <span className="collapsible-header_title_first-half">Proof</span>
                                            <span className="has-text-black">reading</span>
                                        </h2>
                                    </div>
                                    {proofreadingToggle && (
                                        <article id="proofreading_text" className="ml-3 mr-3">
                                            <div
                                                className="has-text-black"
                                                dangerouslySetInnerHTML={{ __html: main.proofReading.text }}
                                            />
                                            <table className="table is-bordered is-narrow rates_table">
                                                <tbody>
                                                    {main.proofReading.rates.map((rate, i) => (
                                                        <tr key={i}>
                                                            <td>{rate.service}</td>
                                                            <td>{rate.pricePound}</td>
                                                            <td>{rate.priceEuro}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </article>
                                    )}

                                    {/* academic editing */}
                                    <div
                                        className="collapsible-header mb-3"
                                        ref={academicEditingRef}
                                        onClick={() => {
                                            academicEditingToggle
                                                ? toggleAcademicEditing(false)
                                                : toggleAcademicEditing(true);
                                        }}
                                    >
                                        <img
                                            className="collapsible-header_image mb-3"
                                            src={
                                                Boolean(main.academicEditing.image) &&
                                                Boolean(main.academicEditing.image.childImageSharp)
                                                    ? main.academicEditing.image.childImageSharp.fixed.src
                                                    : main.academicEditing.image
                                            }
                                            alt={main.academicEditing.heading}
                                        ></img>
                                        <h2 className="collapsible-header_title title">
                                            <span className="collapsible-header_title_first-half">
                                                Academic
                                            </span>
                                            <span className="has-text-black"> editing</span>
                                        </h2>
                                    </div>
                                    {academicEditingToggle && (
                                        <article id="academic-editing_text" className="ml-3 mr-3">
                                            <div
                                                className="has-text-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: main.academicEditing.text,
                                                }}
                                            />
                                            <table className="table is-bordered is-narrow rates_table">
                                                <tbody>
                                                    {main.academicEditing.rates.map((rate, i) => (
                                                        <tr key={i}>
                                                            <td>{rate.service}</td>
                                                            <td>{rate.pricePound}</td>
                                                            <td>{rate.priceEuro}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </article>
                                    )}

                                    {/* editorial assessment */}
                                    <div
                                        className="collapsible-header mb-3"
                                        ref={editorialAssessmentRef}
                                        onClick={() => {
                                            editorialAssessmentToggle
                                                ? toggleEditorialAssessment(false)
                                                : toggleEditorialAssessment(true);
                                        }}
                                    >
                                        <img
                                            className="collapsible-header_image"
                                            src={
                                                Boolean(main.editorialAssessment.image) &&
                                                Boolean(main.editorialAssessment.image.childImageSharp)
                                                    ? main.editorialAssessment.image.childImageSharp.fixed.src
                                                    : main.editorialAssessment.image
                                            }
                                            alt={main.editorialAssessment.heading}
                                        ></img>
                                        <h2 className="collapsible-header_title title">
                                            {/* {main.editorialAssessment.heading} */}
                                            <span className="collapsible-header_title_first-half">
                                                Editorial
                                            </span>
                                            <span className="has-text-black"> assessment</span>
                                        </h2>
                                    </div>
                                    {editorialAssessmentToggle && (
                                        <article id="editorial-assessment_text" className="ml-3 mr-3">
                                            <div
                                                className="has-text-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: main.editorialAssessment.text,
                                                }}
                                            />
                                            <table className="table is-bordered is-narrow rates_table">
                                                <tbody>
                                                    {main.editorialAssessment.rates.map((rate, i) => (
                                                        <tr key={i}>
                                                            <td>{rate.service}</td>
                                                            <td>{rate.pricePound}</td>
                                                            <td>{rate.priceEuro}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </article>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}

                {/* SERVICES  */}
                {/* <div className="column is-10 is-offset-1 has-text-justified"> */}
            </section>
        </div>
    );
};

ServicesPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    header: PropTypes.string,
    mainpitch: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    main: PropTypes.shape({
        editing: PropTypes.shape({
            heading: PropTypes.string,
            rates: PropTypes.array,
        }),
        proofreading: PropTypes.shape({
            heading: PropTypes.string,
            rates: PropTypes.array,
        }),
        academicEditing: PropTypes.shape({
            heading: PropTypes.string,
            rates: PropTypes.array,
        }),
        editorialAssessment: PropTypes.shape({
            heading: PropTypes.string,
            rates: PropTypes.array,
        }),
    }),
};

const ServicesPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <ServicesPageTemplate
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                image={post.frontmatter.image}
                header={post.frontmatter.header}
                mainpitch={post.frontmatter.mainpitch}
                main={post.frontmatter.main}
            />
        </Layout>
    );
};

ServicesPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default ServicesPage;

export const servicesPageQuery = graphql`
    query ServicesPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                header
                mainpitch {
                    title
                    description
                }
                main {
                    editing {
                        image {
                            childImageSharp {
                                fixed(width: 240, height: 184) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        heading
                        text
                        rates {
                            service
                            pricePound
                            priceEuro
                        }
                    }
                    proofReading {
                        image {
                            childImageSharp {
                                fixed(width: 240, height: 184) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        heading
                        text
                        rates {
                            service
                            pricePound
                            priceEuro
                        }
                    }
                    academicEditing {
                        image {
                            childImageSharp {
                                fixed(width: 240, height: 184) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        heading
                        text
                        rates {
                            service
                            pricePound
                            priceEuro
                        }
                    }
                    editorialAssessment {
                        image {
                            childImageSharp {
                                fixed(width: 240, height: 184) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        heading
                        text
                        rates {
                            service
                            pricePound
                            priceEuro
                        }
                    }
                }
            }
        }
    }
`;
